import React from 'react';
import { Row, Col } from 'antd';
import { Form, Input, Button} from 'antd';
import { Link } from "react-router-dom";
import { Typography, } from 'antd';
import { Spin } from 'antd';
import { getDadosDoCondutor } from "../service/api";
import axios from 'axios';
import Progresso from '../Progresso/Progresso';
import baseUrl from "../service/baseUrl";
import Header from '../Header/Header';
import { getLabel} from '../shared'
import { mask as masker } from "remask";
const { Text } = Typography;

const { Title } = Typography;

class Declaracao extends React.Component {

  constructor(props) {
    super(props);

    this.state = { deDeclaracao: '', cpf: '', protocolo: '', contador: '2000', cor: '#000000', msgdeCaractere: ' caracteres', loading: false }

    this.setDeclaracao = this.setDeclaracao.bind(this);
  }
  setDeclaracao(declaracao) {
    let valorTotal = declaracao.target.value.length;
    let contadorRegr = 2000 - valorTotal;
    this.setState({ deDeclaracao: declaracao.target.value, contador: contadorRegr });
    if(contadorRegr === 0){ 
      this.setState({ cor: '#ff2525', msgdeCaractere: ' caracteres restantes' })
    }else{
      this.setState({ cor: '#000000', msgdeCaractere: ' caracteres restantes'})
    }
    if(contadorRegr === 2000){
      this.setState({ msgdeCaractere: ' caracteres restantes'})
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { match: { params } } = this.props;
    getDadosDoCondutor(params.uuid).then((dados) => {
      let cont = 2000;
      if(dados.deDeclaracao !== null){
        cont = Math.abs( dados.deDeclaracao.length - this.state.contador);
      }
      
      this.setState({
        uuid: params.uuid,
        posto: dados.cdPostorodov,
        veiculo: dados.nuVeiculo,
        boletim: dados.nuBoletim,
        ano: dados.nuAno,
        deDeclaracao: dados.deDeclaracao,
        cpf: masker(dados.nuCpfCondutor, '999.999.999-99'),
        protocolo: dados.nuProtocoloRoat,
        contador: cont,
        msgdeCaractere: cont === 2000 ? ' caracteres' : ' caracteres restantes' 
      });
    });
  }

  salvarDadosDeclaracao = () => {
    this.setState({ loading: true });
    axios.put(baseUrl + "condutores/declaracao/",
      {
        "posto": this.state.posto,
        "ano": this.state.ano,
        "boletim": this.state.boletim,
        "veiculo": this.state.veiculo,
        "deDeclaracao": this.state.deDeclaracao,
      }
    ).then(resp => {
      this.setState({ loading: false });
    })
  }
  render() {
    return (
      <div className="background-login">
        <div className="loading" hidden={!this.state.loading}>    
              <Spin className="spinCentro" spinning={this.state.loading} delay={500} size="large"/>
         </div>
        <div className="absolute-wrapper">
          <div className="colored-backgrounds">
            <div></div>
            <div></div>
          </div>
        </div>
        <main>
          <Header
            protocolo={this.state.protocolo}
            cpf={this.state.cpf}
            boletim={this.state.boletim}
            posto={this.state.posto}
            ano={this.state.ano}
            onExit={this.salvarDadosDeclaracao.bind(this)}/>
          <div className="content-declaracao">
            <div className="nest">
              <div className="nestfs">
                <Row className="col-direct" style={{ width: '100%' }}>
                  <Col className="col-forms" lg={18}>
                    <Title className="titulo-tela" level={2}>{getLabel('Declaração do Condutor')}</Title>
                    <form className="form-declaracao">
                      <Row>
                        <Text>{getLabel('TEXTO_DECLARACAO')}<strong> {getLabel('TEXTO_COMPLEMENTO_DECLARACAO')}</strong>
                        </Text>
                        <React.Fragment>
                          <Col md={24}>
                            <Form.Item label={getLabel('LABEL_DECLARACAO')} className="label-declaracao label-float">
                              <Input.TextArea autoSize={{ minRows: 10 }} value={this.state.deDeclaracao} onChange={this.setDeclaracao} placeholder={getLabel('DECLARACAO')} maxLength="2000" />
                              <b style={{ float: "right", color: this.state.cor }} >{this.state.contador} {this.state.msgdeCaractere} </b>
                              <label>{getLabel('DECLARACAO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                      <Col md={24} id="botoes-navegacao">
                      <Form.Item>
                            <Button type="primary" size="large" block onClick={this.salvarDadosDeclaracao} 
                              disabled={!this.state.deDeclaracao}>
                              <Link to={`/habilitacao/${this.state.uuid}`}>Proximo</Link>
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button size="large" block onClick={this.salvarDadosDeclaracao} ><Link to={`/condutor/${this.state.uuid}`} >Anterior</Link></Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </form>
                    </Col>
                    <Progresso percent={64} current={3}/>                  
                </Row>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Declaracao;