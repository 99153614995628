import React from 'react';
import { getLabel } from '../shared'
import { Steps, Col } from 'antd';
const { Step } = Steps;

export default class Progresso extends React.Component {    
     
    render() {
        return (
            <React.Fragment>
              <Col lg={6}>
                <Steps direction="vertical" percent={this.props.percent} current={this.props.current} style={{ width: 'auto' }}>
                      <Step title={getLabel('ITEM_VEICULO')} />
                      <Step title={getLabel('ITEM_PROPRIETARIO')} />
                      <Step title={getLabel('ITEM_CONDUTOR')} />
                      <Step title={getLabel('ITEM_DECLARACAO')} />
                      <Step title={getLabel('ITEM_HABILITACAO')} />
                      <Step title={getLabel('ITEM_AVARIAS')} />
                    </Steps>
              </Col>
            </React.Fragment>
        )
    }
}