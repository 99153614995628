import React from 'react';
import { Row, Col } from 'antd';
import { Form, Input, Button, Select } from 'antd';
import { Typography } from 'antd';
import { message, Spin } from 'antd';
import { Link } from "react-router-dom";
import Progresso from '../Progresso/Progresso'
import axios from 'axios'
import baseUrl from '../service/baseUrl';
import { getPaises, getUnidadeFederal, getMunicipiosPorUf } from "../service/api";
import Header from '../Header/Header';
import { mask as masker, unMask } from "remask";
import { buscadorDeCep } from '../service/cep';
import { SearchOutlined } from '@ant-design/icons';
import { getLabel } from '../shared'
const { Title } = Typography;

class Proprietario extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      cpf: '',
      protocolo: '',
      cdpostorodov: '',
      nuAno: '',
      nuboletim: '',
      nuVeiculo: '',
      nuCPFCGC: '',
      nmProprietario: '',
      cdNacProp: '',
      NMPAIS: '',
      deEndereco: '',
      nuNumeroProp: '',
      deComplementoProp: '',
      nmBairroPropriet: '',
      cdMunicPropriet: '',
      MunicPropriet: '',
      sgUnidFederalPropriet: '',
      nuCepPropriet: '',
      nuTelefone: '',
      municipios: [],
      ufs: [],
      uf: '',
      tipo: '',
      paises: [],
      uuid: '',
      loading: false
    }
    this.setNmProprietario = this.setNmProprietario.bind(this);
    this.setnuCpf = this.setnuCpf.bind(this);
    this.setNacionalidade = this.setNacionalidade.bind(this);
    this.setDeEndereco = this.setDeEndereco.bind(this);
    this.setnuNumeroProp = this.setnuNumeroProp.bind(this);
    this.setComplemento = this.setComplemento.bind(this);
    this.setBairro = this.setBairro.bind(this);
    this.setCep = this.setCep.bind(this);
    this.setTelefone = this.setTelefone.bind(this);
    this.setPais = this.setPais.bind(this);
    this.setMunicipio = this.setMunicipio.bind(this);
    this.setUf = this.setUf.bind(this);
    this.setTipo = this.setTipo.bind(this);
    this.buscaCep = this.buscaCep.bind(this);
    this.retornoSpinCep = this.retornoSpinCep.bind(this);
  }

  salvarDadosProprietario = () => {
    this.setState({ loading: true });
    axios.put(baseUrl + "proprietarios/",
      {
        "posto": this.state.cdpostorodov,
        "ano": this.state.nuAno,
        "boletim": this.state.nuboletim,
        "veiculo": this.state.nuVeiculo,
        "nuCPFCGC": unMask(this.state.nuCPFCGC),
        "nmProprietario": this.state.nmProprietario,
        "cdNacProp": this.state.cdNacProp,
        "deEndereco": this.state.deEndereco,
        "nuNumeroProp": this.state.nuNumeroProp,
        "deComplementoProp": this.state.deComplementoProp,
        "nmBairroPropriet": this.state.nmBairroPropriet,
        "cdMunicPropriet": this.state.cdMunicPropriet,
        "MunicPropriet": this.state.MunicPropriet,
        "sgUnidFederalPropriet": this.state.sgUnidFederalPropriet,
        "nuCepPropriet": unMask(this.state.nuCepPropriet),
        "nuTelefone": this.state.nuTelefone,
      }
    ).then((resp) => {
        this.setState({ loading: false });     
    })

  }

  setNmProprietario(prop) { this.setState({ nmProprietario: prop.target.value }) };
  setnuCpf(prop) {

    if (this.state.tipo === 'Pessoa Física') {
      this.setState({ nuCPFCGC: masker(prop.target.value, ['999.999.999-99']) })
    } else {
      this.setState({ nuCPFCGC: masker(prop.target.value, ['99.999.999/9999-99']) })
    }
  };
  setNacionalidade(prop) { this.setState({ cdNacProp: prop.target.value }) };
  setDeEndereco(prop) { this.setState({ deEndereco: prop.target.value }) };
  setnuNumeroProp(prop) { this.setState({ nuNumeroProp: prop.target.value }) };
  setComplemento(prop) { this.setState({ deComplementoProp: prop.target.value }) };
  setBairro(prop) { this.setState({ nmBairroPropriet: prop.target.value }) };
  setCep(prop) { this.setState({ nuCepPropriet: masker(prop.target.value, '99.999-999') }) };
  setTelefone(prop) { this.setState({ nuTelefone: masker(prop.target.value, '(99)999999999') }) };
  setUf(unidadeFederal) {
    this.setState({ sgUnidFederalPropriet: unidadeFederal })
    getMunicipiosPorUf(unidadeFederal).then((municipio) => { this.setState({ municipios: municipio }) });
  };
  setMunicipio(municipio) { this.setState({ cdMunicPropriet: municipio, MunicPropriet: municipio }) };
  setPais(pais) {
    this.setState({
      cdNacProp: pais
    })
  };

  setTipo(tipo) {
    var cpf = ''; 
    if(tipo === 'Pessoa Física'){
      cpf = masker('', ['999.999.999-99']);
    }else{
      cpf = masker('', ['99.999.999/9999-99']);
    }
    this.setState({ tipo: tipo,
      nuCPFCGC : cpf,
})
  };

  cepCallback = (data) => {  
    this.setState({
      deEndereco: data.street,
      nmBairroPropriet: data.neighborhood,
      cdMunicPropriet: data.cityId,
      sgUnidFederalPropriet: data.state,
      MunicPropriet: data.city,
      loading: false
    });    
  };

  retornoSpinCep = (spin, msg) => {
    this.setState({ loading: spin });
    message.error(msg);
  }
  
  buscaCep (){
    this.setState({ loading: true });
    var ceps = unMask(this.state.nuCepPropriet).toString();
    if(!ceps || ceps.length < 8){
      this.setState({ loading: false });
      message.error("Favor preencher um cep válido.");
      return;
    }
    if(ceps.length === 8){
      buscadorDeCep(ceps, this.cepCallback, this.retornoSpinCep); 
    } else {               
      let mensagem = 'Cep Invalido.';
      message.error(mensagem, [5], null);           
    }    
    
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var othis = this;
    var tipos = '';
    var cpf = '';
    var cep = '';
    const { match: { params } } = this.props;
    axios
      .get(baseUrl + 'proprietarios/' + params.uuid)
      .then(function (response) {
        if(response.data.nuCPFCGC !== null){
          if (response.data.nuCPFCGC < 99999999999) {
            tipos = getLabel('PESSOA_FISICA');
            cpf = masker(response.data.nuCPFCGC, ['999.999.999-99']);

          } else {
            tipos = getLabel('PESSOA_JURIDICA');
            cpf = masker(response.data.nuCPFCGC, ['99.999.999/9999-99']);
          }
        }

        if(null != response.data.nuCepPropriet){
          cep = masker(response.data.nuCepPropriet, '99.999-999');
        }
        othis.setState({
          uuid: params.uuid,
          cdpostorodov: response.data.cdPostorodov,
          nuAno: response.data.nuAno,
          nuboletim: response.data.nuBoletim,
          cpf: masker(response.data.nuCpfCondutor, ['999.999.999-99']),
          protocolo: response.data.nuProtocoloRoat,
          nuVeiculo: response.data.nuVeiculo,
          nuCPFCGC: cpf,
          nmProprietario: response.data.nmProprietario,
          cdNacProp: response.data.cdNacProp,
          NMPAIS: response.data.nmPais,
          deEndereco: response.data.deEndereco,
          nuNumeroProp: response.data.nuNumeroProp,
          deComplementoProp: response.data.deComplementoProp,
          nmBairroPropriet: response.data.nmBairroPropriet,
          cdMunicPropriet: response.data.cdMunicPropriet,
          MunicPropriet: response.data.municPropriet,
          sgUnidFederalPropriet: response.data.sgUnidFederalPropriet,
          nuCepPropriet: cep,
          nuTelefone: response.data.nuTelefone,
          tipo: tipos,
        });
        
        getPaises().then(function (pais) {
          othis.setState({ paises: pais })
        });

        getUnidadeFederal().then(function (uf) {
          othis.setState({
            ufs: uf
          })
        });

        getMunicipiosPorUf(othis.state.sgUnidFederalPropriet).then((municipio) => { othis.setState({ municipios: municipio }) });
      });

  };


  render() {
    const { ufs } = this.state;
    const { municipios } = this.state;
    const { paises } = this.state;
    return (
      <div className="background-login" style={{ height: '100%' }}>
        <div className="loading" hidden={!this.state.loading}>    
              <Spin className="spinCentro" spinning={this.state.loading} delay={500} size="large"/>
         </div>
        <div className="absolute-wrapper">
          <div className="colored-backgrounds">
            <div></div>
            <div></div>
          </div>
        </div>
        <main>
          <Header protocolo={this.state.protocolo}
            cpf={this.state.cpf}
            boletim={this.state.nuboletim}
            posto={this.state.cdpostorodov}
            ano={this.state.nuAno}
            onExit={this.salvarDadosProprietario.bind(this)} />
          <div className="content-declaracao">
            <div className="nest">
              <div className="nestfs">
                <Row className="col-direct" style={{ width: '100%' }}>
                  <Col className="col-forms" lg={18}>
                    <Title className="titulo-tela" level={2}>{getLabel('TITULO_PROPRIETARIO')}</Title>
                    <form className="form-habilitacao">
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('TIPO_PESSOA')} className="label-float">
                              <Select placeholder={getLabel('TIPO_PESSOA')} size="large" value={this.state.tipo} onChange={this.setTipo}>
                                <Select.Option value={getLabel('PESSOA_FISICA')}>{getLabel('PESSOA_FISICA')}</Select.Option>
                                <Select.Option value={getLabel('PESSOA_JURIDICA')}>{getLabel('PESSOA_JURIDICA')}</Select.Option>
                              </Select>
                              <label className="selectlabel">{getLabel('TIPO_PESSOA')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('NOME_RAZAO_SOCIAL')} className="label-float">
                              <Input placeholder={getLabel('NOME_RAZAO_SOCIAL')} size="large" value={this.state.nmProprietario} onChange={this.setNmProprietario} maxLength="150" />
                              <label>{getLabel('NOME_RAZAO_SOCIAL')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('CPF_CNPJ')} className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder={getLabel('CPF_CNPJ')} size="large" value={this.state.nuCPFCGC} onChange={this.setnuCpf} maxLength="18" />
                              <label>{getLabel('CPF_CNPJ')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('NACIONALIDADE')} className="label-float">
                              <Select placeholder={getLabel('SELECIONE_NACIONALIDADE')} size="large" value={this.state.cdNacProp} onChange={this.setPais} defaultValue={this.state.NMPAIS} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {paises.map((pais) => (<Select.Option key={pais} value={pais.cdPais} > {pais.deNacionalidade}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('NACIONALIDADE')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                    </form>
                 
                    <form className="endereco">
                      <Title level={3}>{getLabel('ENDERECO')}</Title>
                      <Row>
                      <React.Fragment>
                          <Col md={8}>
                            <Form.Item label={getLabel('CEP')} className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder={getLabel('CEP')} size="large" value={this.state.nuCepPropriet} onChange={this.setCep} maxLength="10" />
                              <label>{getLabel('CEP')}</label>
                              <Button type="primary" size="large" block onClick={this.buscaCep} icon={<SearchOutlined />} style={{ float: 'right', marginTop: '-40px' }} />
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('LOGRADOURO')} className="label-float">
                              <Input placeholder={getLabel('LOGRADOURO')} size="large" value={this.state.deEndereco} onChange={this.setDeEndereco} maxLength="100"/>
                              <label>{getLabel('LOGRADOURO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={4}>
                            <Form.Item label={getLabel('NUMERO')} className="label-float">
                              <Input placeholder={getLabel('NUMERO')} size="large" value={this.state.nuNumeroProp} onChange={this.setnuNumeroProp} maxLength="10"/>
                              <label>{getLabel('NUMERO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('COMPLEMENTO')} className="label-float">
                              <Input placeholder={getLabel('COMPLEMENTO')} size="large" value={this.state.deComplementoProp} onChange={this.setComplemento} maxLength="50"/>
                              <label>{getLabel('COMPLEMENTO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('BAIRRO')} className="label-float">
                              <Input placeholder={getLabel('BAIRRO')} size="large" value={this.state.nmBairroPropriet} onChange={this.setBairro} maxLength="50"/>
                              <label>{getLabel('BAIRRO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={4}>
                            <Form.Item label={getLabel('UF')} className="label-float">
                              <Select placeholder={getLabel('SELECIONE_UF')} size="large" value={this.state.sgUnidFederalPropriet} onChange={this.setUf} defaultValue={this.state.sgUnidFederalPropriet} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {ufs.map((uf) => (<Select.Option key={uf.cdPais} value={uf.sgUnidadeFederal} >{uf.sgUnidadeFederal}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('UF')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={10}>
                            <Form.Item label={getLabel('MUNICIPIO')} className="label-float">
                              <Select placeholder={getLabel('SELECIONE_MUNICIPIO')} size="large" value={this.state.MunicPropriet} onChange={this.setMunicipio} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {municipios.map((municipio) => (<Select.Option key={municipio.cdMunicipio} value={municipio.cdMunicipio}>{municipio.nmMunicipio}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('MUNICIPIO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={10}>
                            <Form.Item label={getLabel('TELEFONE')} className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder={getLabel('TELEFONE')} size="large" value={this.state.nuTelefone} onChange={this.setTelefone} maxLength="14" />
                              <label>{getLabel('TELEFONE')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <Col md={24} id="botoes-navegacao">
                          <Form.Item>
                            <Button type="primary" size="large" block onClick={this.salvarDadosProprietario}
                              disabled ={!this.state.tipo || !this.state.nuCPFCGC || !this.state.nmProprietario || !this.state.cdNacProp 
                              || !this.state.deEndereco || !this.state.nmBairroPropriet || !this.state.sgUnidFederalPropriet 
                              || !this.state.cdMunicPropriet || !this.state.nuCepPropriet || !this.state.nuTelefone}>
                              <Link to={`/condutor/${this.state.uuid}`} >Proximo</Link>
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button size="large" block onClick={this.salvarDadosProprietario}><Link to={`/veiculo/${this.state.uuid}`}>Anterior</Link></Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                  <Progresso percent={32} current={1}/>                   
                </Row>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Proprietario;