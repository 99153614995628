export default {

    TEXTO_MODAL_HEADER:         'Prezado usuário, você está saindo do sistema sem finalizar as edições',
    TEXTO_DOIS_MODAL_HEADER:    'Retorne em outro momento para concluir as informações, '
                                    +'de forma que a PMRv possa revisar os dados e liberar o '
                                    +'registro para impressão definitiva.',
    TEXTO_MODAL_FINALIZAR:       'Ao finalizar a edição, não será mais possível mudar as informações '
                                    + 'deste registro de acidentes. Deseja continuar?',
    TEXTO_DECLARACAO:           'Prezado Condutor, preencha o campo abaixo com a sua declaração, acerca dos '
                                    +'fatos que levaram à ocorrência do acidente aqui descrito neste registro '
                                    +'on line, de forma a contemplar os seguintes questionamentos:',
    TEXTO_COMPLEMENTO_DECLARACAO:     'O quê? Quando? Onde? Como? e Por quê?',                            
    TITULO_VEICULO:             'Dados do Veículo',
    TITULO_PROPRIETARIO:        'Dados do Proprietário',
    TITULO_CONDUTOR:            'Dados do Condutor',
    TITULO_DECLARACAO:          'Declaração do Condutor',
    TITULO_HABILITACAO:         'Dados da Habilitação',
    TITULO_AVARIAS:             'Fotos das Avarias',
    TITULO_ROAT:                'Registro On Line de Acidentes de Trânsito - ROAT',
    TITULO_COMPLETE_AQUI:       'Complemente aqui suas informações do Boletim previamente iniciado via contato 198.',
    TITULO_CRITERIOS:           'Para ser possível o',
    TITULO_CRITERIOS_COMPLENTO: ', a ocorrência deve obedecer os seguintes critérios:',

    CRITERIO_UM:                '. Ao utilizar o ROAT, o usuário concorda que as informações prestadas serão utilizadas '
                                    +'de maneira sigilosa, para geração do Boletim e para fins estatísticos e de estudos, ' 
                                    +'objetivando a prevenção de acidentes, melhoria nas condições das rodovias e o reforço da segurança pública;',
    CRITERIO_DOIS:              '. Não podem haver feridos, nem mesmo leves;',
    CRITERIO_TRES:              '. O acidente não pode ter envolvido mais de ',
    CRITERIO_QUATRO:            '. O acidente não pode ter envolvido veículo com produto perigoso, em que tenha havido avaria no compartimento '
                                    +' de carga a granel, com derramamento ou vazamento do produto;',
    CRITERIO_CINCO:             '. No acidente não pode ter havido dano a qualquer patrimônio público, tanto da SIE/PMRV quanto de outros órgãos;',
    CRITERIO_SEIS:              '. Todos os condutores dos veículos devem ser habilitados;',
    CRITERIO_SETE:              '. Nenhum dos condutores pode ter se evadido do local do acidente;',
    CRITERIO_OITO:              '. Nenhum dos condutores dos veículos pode aparentar ter ingerido bebida alcoólica ou substância psicoativa;',
    CRITERIO_NOVE:              '. Não pode haver envolvimento de veículos estrangeiros. Obs: Caso haja o envolvimento de cidadãos estrangeiros, ' 
                                    +'estes devem possuir CPF.',

    ITEM_VEICULO:               'Dados do Veículo',
    ITEM_PROPRIETARIO:          'Dados do Proprietário',
    ITEM_CONDUTOR:              'Dados do Condutor',
    ITEM_DECLARACAO:            'Declaração do Condutor',
    ITEM_HABILITACAO:           'Dados da Habilitação',
    ITEM_AVARIAS:               'Fotos das Avarias',

    AGUARDE_HOMOLOGACAO:        'Aguarde a homologação do seu boletim.',
    ALT_YOUTUBE:                'Link treinamento Roat youtube',
    
    CADASTRO_E_CORRECAO:        'Cadastramento e Correção',
    CEP_VALIDO:                 'Favor preencher um cep válido',
    CEP_INVALIDO:               'Cep Inválido',
    COMPLETE_CAMPO:             'Preencha todos os campos para realizar a pesquisa.',

    IMPRESSAO_DEFINITIVA:       'Impressão Definitiva',
    IMPRESSAO_PARCIAL:          'Impressão Parcial',  

    NAO_ENCONTRADO:             'Não foi encontrado registro de acidente para os parâmetros '
                                    +'informados. Corrija a informação e tente novamente, ou entre '
                                    +'em contato com a PMRv para maiores esclarecimentos.',

    PRAZO_EXPIROU:              'O prazo de 180 dias para edição do boletim se expirou.',
    PRAZO_ESGOTOU:              'O prazo para preenchimento do ROAT se esgotou em ',
    PRAZO_ESGOTOU_COMPLEMENTO:  '. Não é mais possível realizar a complementação das informações. '
                                   +'Entre em contato com a PMRv.',
     
    RESULTADO_CONSULTA:         'Resultado da Consulta',

    TIPO_FOTO:                  'A imagem deve estar no formato JPEG/PNG.',
    TOOLTIP_YUTUBE:             'Clique para acessar as Instruções de Uso do ROAT',

   
    ANO:                        'Ano',
    ANO_FAB:                    'Ano Fab.',
    APOLICE:                    'Nº Apólice',

    BAIRRO:                     'Bairro',

    CATEGORIA:                  'Categoria',
    CEP:                        'Cep',
    COMPLEMENTO:                'Complemento',
    COR:                        'Cor',
    CODIGO_ACESSO:              'Código Acesso',
    CPF:                        'CPF',
    CPF_CNPJ:                   'CPF/CNPJ',
    CPF_ENVOLVIDO:              'CPF  Envolvido',

    DATA_NASCIMENTO:            'Data de Nascimento',
    DATA_PRIMEIRA_CNH:          'Data 1ª CNH',
    DECLARACAO:                 'Declaração',
    DIANTEIRA:                  'Dianteira',

    EMAIL:                      'E-mail',
    ENDERECO:                   'Endereço',
    ESPECIE:                    'Espécie',
    ESCOLHA_CATEGORIA:          'Escolha a Categoria',
    ESCOLHA_COR:                'Escolha a Cor',
    ESCOLHA_ESPECIE:            'Escolha a Espécie',    
    ESCOLHA_MARCA:              'Escolha a Marca/Modelo',
    ESCOLHA_TIPO:               'Escolha o Tipo',
    ESTADO_CIVIL:               'Estado Civil',

    FEMININO:                   'Feminino',
    FINALIZAR:                  'Finalizar',

    LABEL_DECLARACAO:           'Insira aqui sua declaração',

    LATERAL_DIREITA:            'Lateral Direita',
    LATERAL_ESQUERDA:           'Lateral Esquerda',
    LOGRADOURO:                 'Logradouro',

    MARCA:                      'Marca/Modelo',
    MASCULINO:                  'Masculino',
    MOTIVO:                     'Motivo Viagem',
    MUNICIPIO:                  'Município',

    NOME:                       'Nome',
    NOME_RAZAO_SOCIAL:          'Nome/Razão Social',
    NACIONALIDADE:              'Nacionalidade',
    NATURALIDADE:               'Naturalidade',
    NU_DPVAT:                   'Nº DPVAT',
    NUMERO:                     'Número',
    NUMERO_REGISTRO:            'Nº de Registro',

    PAIS:                       'Pais',
    PLACA:                      'Placa',
    PESSOA_FISICA:              'Pessoa Física',
    PESSOA_JURIDICA:            'Pessoa Jurídica',
    POSTO:                      'Posto',
    PROTOCOLO:                  'Protocolo',

    RENAVAM:                    'Renavam',
    RG:                         'RG',    
    ROAT:                       'Roat',
    RUA:                        'Rua',

    SELECIONE_UF:               'Selecione o Estado',
    SELECIONE_MUNICIPIO:        'Selecione o Município',
    SELECIONE_NACIONALIDADE:    'Selecione a Nacionalidade',
    SELECIONE_PAIS:             'Selecione o Pais',
    SEGURADORA:                 'Seguradora',
    SEXO:                       'Sexo',

    TRASEIRA:                   'Traseira',
    TELEFONE:                   'Telefone',
    TIPO:                       'Tipo',
    TIPO_PESSOA:                'Tipo de Pessoa',

    UF:                         'UF',
    UF_RG:                      'UF do RG',

    VALIDADE:                   'Validade',  
    VEICULOS:                   'veículos',  
   
};
