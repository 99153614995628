import React from "react";
import { Row, Col } from "antd";
import { Form, Input, Button, Select } from "antd";
import { Typography } from "antd";
import { message, Spin} from "antd";
import axios from 'axios';
import { getCor, getEspecie, getCategoria, getTipo, getMotivoViagem, getUnidadeFederal, getMunicipiosPorUf, getDadosDoVeiculo, getDadosDoVeiculoSC } from "../service/api";
import baseUrl from "../service/baseUrl";
import { mask as masker } from "remask";
import Header from '../Header/Header';
import Progresso from '../Progresso/Progresso'
import { getLabel } from '../shared'
const { Title } = Typography;

class Veiculo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cores: [], tipos: [], categorias: [], marcas: [], especies: [], viagens: [], municipios: [], ufs: [], dadosSC: [],
      posto: '', ano: '', boletim: '', veiculo: '', cpf: '', protocolo: '', placa: '', renavam: '', renavamSC: '', anoFab: '', nDPVAT: '', seguradora: '', apolice: '',
      tipo: '', cdCategoria: '', marca: '', municipio: '', especie: '', viagem: '', uf: '', cor: '', pais: '', deCorVeiculo: '', deMunicipio: '',
      deCategoria: '', deMotivoViagem: '', deMarcaModelo: '', deTipoVeiculo: '', unidadeFederal: '', uuid: '', disabled: true, nmProprietario: '', nuCPFCGC: '', loading: false
    };
    const { match: { params } } = this.props;
    getDadosDoVeiculoSC(params.uuid).then((dadosSC) =>{
      this.setState({renavamSC: dadosSC.nuRenavam, dadosSC: dadosSC});
    })
    this.setUf = this.setUf.bind(this);
    this.setCor = this.setCor.bind(this);
    this.setDPVAT = this.setDPVAT.bind(this);
    this.setTipo = this.setTipo.bind(this);
    this.setPlaca = this.setPlaca.bind(this);
    this.setMarca = this.setMarca.bind(this);
    this.setAnoFab = this.setAnoFab.bind(this);
    this.setViagem = this.setViagem.bind(this);
    this.setRenavam = this.setRenavam.bind(this);
    this.setApolice = this.setApolice.bind(this);
    this.setEspecie = this.setEspecie.bind(this);
    this.setMunicipio = this.setMunicipio.bind(this);
    this.setCategoria = this.setCategoria.bind(this);
    this.setSeguradora = this.setSeguradora.bind(this);
    this.getMarcaModelo = this.getMarcaModelo.bind(this);
    this.setDadosDoVeiculoSC = this.setDadosDoVeiculoSC.bind(this);    
  }

  setDPVAT(DPVAT) { this.setState({ nDPVAT: DPVAT.target.value }); };
  setPlaca(placa) { this.setState({ placa: placa.target.value }); };
  setApolice(apolice) { this.setState({ apolice: apolice.target.value }); };
  setRenavam(renavam) {
    this.setState({ renavam: renavam.target.value });
  };
  setSeguradora(seguro) { this.setState({ seguradora: seguro.target.value }) };
  setAnoFab(anoFabricacao) { this.setState({ anoFab: anoFabricacao.target.value }); };
  setUf(unidadeFederal) {
    this.setState({ uf: unidadeFederal });
    getMunicipiosPorUf(unidadeFederal).then((municipio) => { this.setState({ municipios: municipio }); });
  };
  setCor(cor) { this.setState({ cor: cor, deCorVeiculo: cor }) };
  setTipo(tipo) { this.setState({ tipo: tipo, deTipoVeiculo: tipo }) };
  setCategoria(cat) { this.setState({ cdCategoria: cat, deCategoria: cat }); };
  setViagem(viagem) { this.setState({ viagem: viagem, deMotivoViagem: viagem }) };
  setMarca(marcaMod) { this.setState({ marca: marcaMod, deMarcaModelo: marcaMod }) };
  setEspecie(especie) { this.setState({ especie: especie }) };
  setMunicipio(municipio) { this.setState({ municipio: municipio, deMunicipio: municipio }); };
 
  componentDidMount() {
    const { match: { params } } = this.props;
    getDadosDoVeiculoSC(params.uuid).then((dadosSC) =>{
      this.setState({renavamSC: dadosSC.nuRenavam, dadosSC: dadosSC});      
    });
    window.scrollTo(0, 0);
    getTipo().then((tipo) => { this.setState({ tipos: tipo }) });
    getCategoria().then((categoria) => { this.setState({ categorias: categoria }) });
    getCor().then((cor) => { this.setState({ cores: cor }) });
    getEspecie().then((especie) => { this.setState({ especies: especie }) });
    getMotivoViagem().then((viagem) => { this.setState({ viagens: viagem }) });
    getUnidadeFederal().then((uf) => { this.setState({ ufs: uf }) });  

    getDadosDoVeiculo(params.uuid).then((dados) => {
      this.setState({ 
        disabled: !dados.nuRenavam || this.state.renavamSC !== dados.nuRenavam ? false : true,        
        renavam: dados.nuRenavam,
        uuid: params.uuid,
        posto: dados.cdPostorodov,
        veiculo: dados.nuVeiculo,
        boletim: dados.nuBoletim,
        ano: dados.nuAno,
        placa: dados.nuPlaca,        
        protocolo: dados.nuProtocoloRoat,            
        cpf: masker(dados.nuCpfCondutor, '999.999.999-99'),        
        anoFab: dados.nuAnoVeiculo,
        nDPVAT: dados.nuDpvat,
        seguradora: dados.nmSeguradora,
        apolice: dados.nuApolice,
        tipo: dados.cdTipoVeiculo,
        deTipoVeiculo: dados.deTipoVeiculo,
        cdCategoria: dados.cdCategVeiculo,
        marca: dados.cdMarcaVeiculo,
        deMarcaModelo: dados.deMarcaModelo,
        deMunicipio: dados.municVeiculo,
        municipio: dados.cdMunicipio,
        deCategoria: dados.deCategoria,
        especie: dados.cdEspecieVeiculo,
        viagem: dados.cdMotivoViagem,
        uf: dados.sgUfVeiculo,
        cor: dados.cdCorVeiculo,
        deCorVeiculo: dados.deCor,
        deMotivoViagem: dados.deMotivoViagem,
        unidadeFederal: dados.sgUfVeiculo,       
        nmProprietario: dados.nmProprietario,
        nuCPFCGC: dados.nuCPFCGC   
      });

      getMunicipiosPorUf(dados.sgUfVeiculo).then((municipio) => { 
        this.setState({ municipios: municipio }) 
      });
    });
    this.getMarcaModelo();
    
  }

  navigateToProprietario = () => {
      this.props.history.push(`/proprietario/${this.state.uuid}`);
  };

  salvarDadosVeiculo = (_callbackOnSave) => { 
    this.setState({ loading: true });
    axios.put(baseUrl + "veiculos/", {
        "posto": this.state.posto,
        "ano": this.state.ano,
        "boletim": this.state.boletim,
        "veiculo": this.state.veiculo,
        "especie": this.state.especie,
        "tipo": this.state.tipo,
        "marca": this.state.marca,
        "cor": this.state.cor,
        "anoVeiculo": this.state.anoFab,
        "categoria": this.state.cdCategoria,
        "dpvat": this.state.nDPVAT,
        "viagem": this.state.viagem,
        "uf": this.state.uf,
        "municipio": this.state.municipio,
        "placa": this.state.placa,
        "renavam": this.state.renavam,
        "seguradora": this.state.seguradora,
        "apolice": this.state.apolice,
        "nmProprietario": this.state.nmProprietario,
        "nuIdentSujeito": this.state.nuCPFCGC
    }).then((resp) => {
      if (_callbackOnSave) _callbackOnSave();
        this.setState({ loading: false });     
    }).catch((e) => {
      message.error("Erro ao salvar os dados.")
    });
  }  

  getMarcaModelo = value => {
    let consultaInic = 'A.'
    let marca = encodeURIComponent(!value ? consultaInic : value);
    if(marca.length >= consultaInic.length ){      
    fetch(`${baseUrl}veiculos/marcamodelos/${marca}`)
      .then(response => response.json())
      .then(body => {        
        const data = body.map(marca => ({
          k: marca.k,
          v: marca.v,
        }));
        this.setState({ marcas: data.sort((a, b) => a.v < b.v ? -1 : a.v > b.v ? 1 : 0)})
      });
    }
  };

  setDadosDoVeiculoSC = (renavam) => { 
    let nuCpFCnpj = '';
    if (!this.state.renavamSC) return;

    if (renavam !== this.state.renavamSC) {
      let mensagem = 'O RENAVAM informado está incorreto para esta placa. Preencha o número correto para que todos os dados sejam preenchidos automaticamente.';
      message.error(mensagem, [5], null);
      return;
    }
    let dadosPlacaSc = this.state.dadosSC; 
    nuCpFCnpj = dadosPlacaSc.nuIdentsujeito.slice(0, 3) !== '000' ? dadosPlacaSc.nuIdentsujeito : dadosPlacaSc.nuIdentsujeito.slice(3);        
    this.setState({
      disabled: true,  
      especie: dadosPlacaSc.cdEspecie,
      cor: dadosPlacaSc.cdCor,
      municipio: dadosPlacaSc.cdMunicipio,
      tipo: dadosPlacaSc.cdTipoveiculo,
      cdCategoria: dadosPlacaSc.cdCategoria,
      deCategoria: dadosPlacaSc.deCategoria,
      deCorVeiculo: dadosPlacaSc.deCor,
      deMarcaModelo: dadosPlacaSc.deMarcamodelo,
      deTipoVeiculo: dadosPlacaSc.deTipoveiculo,
      deMunicipio: dadosPlacaSc.nmMunicipio,
      anoFab: dadosPlacaSc.nuAnofabricacao,
      uf: dadosPlacaSc.sgUnidadefederal,
      unidadeFederal: dadosPlacaSc.sgUnidadefederal,
      marca: dadosPlacaSc.cdMarcamodelo,
      nmProprietario: dadosPlacaSc.nmProprietario,
      nuCPFCGC: nuCpFCnpj,
    });               
  }

  render() {
    const { cores } = this.state;
    const { tipos } = this.state;
    const { categorias } = this.state;
    const { marcas } = this.state;
    const { especies } = this.state;
    const { viagens } = this.state;
    const { ufs } = this.state;
    const { municipios } = this.state; 
    return (      
      <div className="background-login" style={{ height: '100%' }}>
        <div className="loading" hidden={!this.state.loading}>    
              <Spin className="spinCentro" spinning={this.state.loading} delay={500} size="large"/>
         </div>
        <div className="absolute-wrapper">
          <div className="colored-backgrounds">
            <div></div>
            <div></div>
          </div>
        </div>
        <main>
          <Header protocolo={this.state.protocolo}
            cpf={this.state.cpf}
            boletim={this.state.boletim}
            posto={this.state.posto}
            ano={this.state.ano}
            onExit={this.salvarDadosVeiculo.bind(this)} />
          <div className="content-declaracao">
            <div className="nest">
              <div className="nestfs">
                <Row className="col-direct" style={{ width: '100%' }}>
                  <Col className="col-forms" lg={18}>
                    <Title className="titulo-tela" level={2}>{getLabel('TITULO_VEICULO')}</Title>
                    <form className="form-veiculo">
                      <Row>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label={getLabel('PLACA')} className="label-float">
                              <Input placeholder={getLabel('PLACA')} disabled={true} size="large" value={this.state.placa} onChange={this.setPlaca} maxLength="10"/>
                              <label>{getLabel('PLACA')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label={getLabel('RENAVAM')} className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" disabled={this.state.disabled} placeholder={getLabel('RENAVAM')} size="large" onBlurCapture={() => this.setDadosDoVeiculoSC(this.state.renavam)} value={this.state.renavam} onChange={this.setRenavam} maxLength="25"/>
                              <label>{getLabel('RENAVAM')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label={getLabel('ANO_FAB')} className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" disabled={this.state.disabled} placeholder={getLabel('ANO_FAB')} size="large" value={this.state.anoFab} onChange={this.setAnoFab} maxLength="4"/>
                              <label>{getLabel('ANO_FAB')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={16}>
                            <Form.Item label={getLabel('TIPO')} className="label-float">
                              <Select disabled={this.state.disabled} placeholder={getLabel('ESCOLHA_TIPO')} size="large" value={this.state.deTipoVeiculo} onChange={this.setTipo} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {tipos.map((tipo) => (<Select.Option key={tipo.cdTipoveiculo} value={tipo.cdTipoveiculo} >{tipo.deTipoveiculo}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('TIPO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label={getLabel('NU_DPVAT')} className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder={getLabel('NU_DPVAT')} size="large" value={this.state.nDPVAT} onChange={this.setDPVAT} maxLength="15" />
                              <label>{getLabel('NU_DPVAT')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('CATEGORIA')} className="label-float">
                              <Select disabled={this.state.disabled} placeholder={getLabel('ESCOLHA_CATEGORIA')} size="large" value={this.state.deCategoria} onChange={this.setCategoria} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {categorias.map((categoria) => (<Select.Option key={categoria.cdCategoria} value={categoria.cdCategoria} >{categoria.deCategoria}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('CATEGORIA')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('MARCA')} className="label-float">
                              <Select disabled={this.state.disabled} placeholder={getLabel('ESCOLHA_MARCA')} 
                                      onSearch={this.getMarcaModelo} size="large" 
                                      value={this.state.deMarcaModelo} onChange={this.setMarca} showSearch 
                                      filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {marcas.map((modelo) => (<Select.Option key={modelo.k} value={modelo.k}>{modelo.v}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('MARCA')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('COR')} className="label-float">
                              <Select disabled={this.state.disabled} placeholder={getLabel('ESCOLHA_COR')} 
                                      size="large" value={this.state.deCorVeiculo} onChange={this.setCor} showSearch
                                      filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {cores.map((cor) => (<Select.Option key={cor.cdCor} value={cor.cdCor} >{cor.deCor}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('COR')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('ESPECIE')} className="label-float">
                              <Select disabled={this.state.disabled} placeholder={getLabel('ESCOLHA_ESPECIE')} 
                                      size="large" value={this.state.especie} onChange={this.setEspecie} showSearch
                                      filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {especies.map((especie) => (<Select.Option key={especie.cdEspecie} value={especie.cdEspecie}>{especie.deEspecie}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('ESPECIE')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('UF')} className="label-float">
                              <Select disabled={this.state.disabled} placeholder={getLabel('ESCOLHA_UF')} size="large" value={this.state.uf} onChange={this.setUf} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {ufs.map((uf) => (<Select.Option key={uf.cdPais} value={uf.sgUnidadeFederal} >{uf.sgUnidadeFederal}</Select.Option>))}
                              </Select>
                              <label className="selectlabel" >{getLabel('UF')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label={getLabel('MUNICIPIO')} className="label-float">
                              <Select disabled={this.state.disabled} placeholder={getLabel('SELECIONE_MUNICIPIO')} 
                                      size="large" value={this.state.deMunicipio} onChange={this.setMunicipio} showSearch 
                                      filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {municipios.map((municipio) => (<Select.Option key={municipio.cdMunicipio} value={municipio.cdMunicipio}>{municipio.nmMunicipio}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('MUNICIPIO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={16}>
                            <Form.Item label={getLabel('SEGURADORA')} className="label-float">
                              <Input placeholder={getLabel('SEGURADORA')} size="large" value={this.state.seguradora} onChange={this.setSeguradora} maxLength="50" />
                              <label>{getLabel('SEGURADORA')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label={getLabel('APOLICE')} className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder={getLabel('APOLICE')} size="large" value={this.state.apolice} onChange={this.setApolice} maxLength="20"/>
                              <label>{getLabel('APOLICE')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={24}>
                            <Form.Item label={getLabel('MOTIVO')} className="label-float">
                              <Select  placeholder={getLabel('MOTIVO')} size="large" value={this.state.deMotivoViagem} onChange={this.setViagem} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {viagens.map((viagem) => (<Select.Option key={viagem.cdMotivoviagem} value={viagem.cdMotivoviagem}>{viagem.deMotivoviagem}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">{getLabel('MOTIVO')}</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <Col md={24} id="botoes-navegacao">
                          <Form.Item>
                            <Button type="primary" size="large" block onClick={() => this.salvarDadosVeiculo(this.navigateToProprietario)} 
                              disabled={!this.state.renavam || !this.state.tipo || !this.state.deCategoria || !this.state.placa 
                              || !this.state.marca || !this.state.cor || !this.state.especie || !this.state.uf || !this.state.municipio 
                              || !this.state.viagem}>
                              Próximo
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                  <Progresso percent={16} current={0}/> 
                </Row>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Veiculo;
