import React from 'react';
import { Row, Col } from 'antd';
import { Form, Input, Button } from 'antd';
import { Typography } from 'antd';
import { Divider } from 'antd';
import { List } from 'antd';
import { Modal } from 'antd';
import { Alert} from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import { PrinterTwoTone } from '@ant-design/icons';
import axios from 'axios'
import { baseUrl, baseUrlAct } from '../service/baseUrl';
import { Link } from 'react-router-dom';
import { mask as masker, unMask } from "remask";
import { getParametro } from "../service/api";
import logoSIE from "../imagens/home/logo-sie.png";
import logoPMSC from "../imagens/home/logopmsc.png";
import logoYoutube from "../imagens/home/logoYoutube.png";
import car from "../imagens/home/car.png";
import carBlue from "../imagens/home/car-blue.png";
import explosion from "../imagens/home/explosion.png";  
import { Tooltip, Spin } from 'antd';
import { getBaseDados } from "../service/baseDados"
import { getLabel } from '../shared';

const { Title } = Typography;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      cpf: '',
      codigo: '',
      protocolo: '',
      dados: '',
      cdPostorodov: '',
      diasPassados: '',
      flEdicaoroatfinalizado: '',
      nuAno: '',
      nuBoletim: '',
      nuVeiculo: '',
      situacao: '',
      nmCondutor: '',
      uuid: '',
      situacaoImpressao: 'P',
      botaoEditar: false,
      botaoImprimir: false,
      alertaCad: false,
      alertaImp: false,
      diasPassadosIniciado: '',
      dtInicioPreenchimentoRoat: '',
      flRoatHomologado: '',
      prmNuMaxVeicROAT: '',
      marcaBaseDados: '',
      antiToolTipOpen: '',
      hiddenDesck: '',
      hiddenMob: '',
      defaultVisible: 'false',
      fimPagina: 0,
      loading: false,
    }
    this.handleChangeCpf = this.handleChangeCpf.bind(this);
    this.handleChangeCodigo = this.handleChangeCodigo.bind(this);
    this.handleChangePro = this.handleChangePro.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOkMensagem = this.handleOkMensagem.bind(this);
    this.handleCancelMensagem = this.handleCancelMensagem.bind(this);
    this.showModal = this.showModal.bind(this);
    this.limpar = this.limpar.bind(this);
    this.scrollYoutubeListMobile = this.scrollYoutubeListMobile.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    
  }

  handleScroll = () => {  
    let AlturaAtivaToolTip = 767;  
    if (window.scrollY >= AlturaAtivaToolTip ) {
      this.setState({       
        defaultVisible: 'true',
      })
    }else{
      this.setState({       
        defaultVisible: 'false',
    }) 
   }   
  }

  scrollYoutubeListMobile = () => {    
    let isTelaMobile =  window.screen.width < 1280;
    if(isTelaMobile){
      this.setState({
        hiddenDesck: 'hidden',
        hiddenMob: '',       
     });          
    }else{
      this.setState({          
          hiddenMob: 'hidden',
          hiddenDesck: '',
      });      
    } 
  }

  showModal = () => {  
    this.setState({ loading: true }); 
    var othis = this;    
    if (this.state.protocolo === "" || this.state.codigo === "" || this.state.cpf === "") {
      othis.setState({
        visible: false,
        visibleMensagem: true,
        mensagem : getLabel('COMPLETE_CAMPO'),        
      });
    }
    
    axios
      .get(baseUrl + 'protocolo/' + this.state.protocolo + '/codigo/' + this.state.codigo + '/cpf/' + unMask(this.state.cpf))
      .then(function (response) {
        var dataFim= new Date(response.data.dtFimPreenchimentoRoat);
        dataFim.setDate(dataFim.getDate() + 1);
                
        if (!response.data) {
          othis.setState({
            visible: false,
            visibleMensagem: true,
            mensagem : getLabel('NAO_ENCONTRADO')
          });
          return;
        }        
        
        if (!response.data.dtFimPreenchimentoRoat && response.data.diasPassadosSemIniciar > 180 && !(response.data.situacao === othis.state.situacaoImpressao
          || response.data.flRoatHomologado === "S")) {
          othis.setState({
            visible: false,
            visibleMensagem: true,
            mensagem : getLabel('PRAZO_EXPIROU')   
          });
          return;
        }
        
        if (response.data.dtFimPreenchimentoRoat && dataFim.getTime() < new Date().getTime()
         && !(response.data.situacao === othis.state.situacaoImpressao
          || response.data.flRoatHomologado === "S")) {
          othis.setState({
            visible: false,
            visibleMensagem: true,
            mensagem : getLabel('PRAZO_ESGOTOU') + new Date(response.data.dtFimPreenchimentoRoat)
            .toLocaleDateString() + getLabel('PRAZO_ESGOTOU_COMPLEMENTO') });
          return;
        }
        var alertaImpressaoDefinitiva = response.data.situacao === othis.state.situacaoImpressao;

        var permiteImpressao = response.data.situacao === othis.state.situacaoImpressao
          || response.data.flRoatHomologado === "S";

        var permiteEdicao = response.data.situacao !== othis.state.situacaoImpressao
          && response.data.flEdicaoroatfinalizado === "N"
          && response.data.flRoatHomologado === "N";

        othis.setState({
          visible: true,
          cdPostorodov: response.data.cdPostorodov,
          diasPassados: response.data.diasPassadosSemIniciar,
          diasPassadosIniciado: response.data.diasPassadosIniciado,
          flEdicaoroatfinalizado: response.data.flEdicaoroatfinalizado,
          nuAno: response.data.nuAno,
          nuBoletim: response.data.nuBoletim,
          nuVeiculo: response.data.nuVeiculo,
          situacao: response.data.situacao,
          nmCondutor: response.data.nmCondutor,
          uuid: response.data.uuid,
          botaoEditar: permiteEdicao,
          botaoImprimir: permiteImpressao,
          alertaCad: !alertaImpressaoDefinitiva,
          alertaImp: alertaImpressaoDefinitiva,
          flRoatHomologado: response.data.flRoatHomologado,
        });
      }).then((resp) => {
         this.setState({ loading: false });
      });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleOkMensagem = e => {
    this.setState({
      visibleMensagem: false,
    });
  };

  limpar = e => {
    this.setState({
      visible: false,
      cpf: '',
      codigo: '',
      protocolo: '',
      dados: '',
      cdPostorodov: '',
      diasPassados: '',
      flEdicaoroatfinalizado: '',
      nuAno: '',
      nuBoletim: '',
      nuVeiculo: '',
      situacao: '',
      mensagem: '',
      uuid: '',
      alert: '',
      alertaCad: false,
      alertaImp: false,      
    });
  };

  handleChangeCpf = e => {
    this.setState({ cpf: masker(e.target.value, '999.999.999-99') });
  }

  handleChangeCodigo = e => {
    this.setState({ codigo: e.target.value.toUpperCase() });
  }

  handleChangePro = e => {
    this.setState({ protocolo: e.target.value });
  }

  handleCancelMensagem = e => {
    this.setState({
      visibleMensagem: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

   componentDidMount() {
    var othis = this;
    getParametro("prmNuMaxVeicROAT")
      .then(function (response) {
        othis.setState({
          prmNuMaxVeicROAT: response.data.vlParametro
        })
      });

      getBaseDados().then(function(response){
        othis.setState({marcaBaseDados: response})
      })

      
      this.setState({fimPagina: document.body.scrollHeight + document.body.clientHeight});
      this.scrollYoutubeListMobile();
      window.onscroll = () => this.handleScroll();
  }


  render() {
    return (
      <div className="background-login" style={{ 'height': '100vh' }}>
         <div className="loading" hidden={!this.state.loading}>    
              <Spin className="spinCentro" spinning={this.state.loading} delay={500} size="large"/>
         </div>
        <div className="absolute-wrapper">
          <div className="colored-backgrounds">          
            <div>
              <div id="colored-bg-img">
                <img id="car" src={car} alt="Icone de carro"/>
                <img className="fa-blink" id="explosion" src={explosion} alt="Icone de explosão" />
              </div>
            </div>
            <div>
              <div id="colored-bg-right">
                <img id="bluecar" src={carBlue} alt="Icone carro azul" />
              </div>
            </div>
          </div>
        </div>
        <main>
          
          <div className="content-declaracao" style={{ marginTop: '0' }}>
            <Row>
              <Col className="texto-intro" md={16} style={{ padding: '2rem' }}>
                <Title level={2}>{getLabel('TITULO_COMPLETE_AQUI')}</Title>
                <Divider plain>{getLabel('TITULO_CRITERIOS')} <strong>{getLabel('TITULO_ROAT')}</strong>{getLabel('TITULO_CRITERIOS_COMPLENTO')}</Divider>
                <List className="criterios"
                  itemLayout="horizontal"
                  dataSource={
                    [
                      {
                        title: '1',
                        description: getLabel('CRITERIO_UM')
                      },
                      {
                        title: '2',
                        description: getLabel('CRITERIO_DOIS'),
                      },
                      {
                        title: '3',
                        description: getLabel('CRITERIO_TRES') + `${this.state.prmNuMaxVeicROAT} ` + getLabel('VEICULOS') + ';',
                      },
                      {
                        title: '4',
                        description: getLabel('CRITERIO_QUATRO'),
                      },
                      {
                        title: '5',
                        description: getLabel('CRITERIO_CINCO'),
                      },
                      {
                        title: '6',
                        description: getLabel('CRITERIO_SEIS'),
                      },
                      {
                        title: '7',
                        description: getLabel('CRITERIO_SETE'),
                      },
                      {
                        title: '8',
                        description: getLabel('CRITERIO_OITO'),
                      },
                      {
                        title: '9',
                        description: getLabel('CRITERIO_NOVE'),
                      },
                    ]
                  }
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>

                  )}
                />                  
              </Col>
              <Col id="form-roat" md={8}>
                <form className="login100-form validate-form">
                  <Title level={2}>{getLabel('ROAT')} </Title>
                  <Title level={2} className="ant-typography marcadagua">{this.state.marcaBaseDados}</Title>
                  <div className="figure">
                    <div className="post-image">
                      <img src={logoSIE} alt="logo da Sie"/>
                      </div>
                      <div className="post-image">
                        <img src={logoPMSC} alt="logo da PMSC"/>
                      </div>
                  </div>
                      <Form name="basic" initialValues={{ remember: true }}>
                        <Form.Item className="label-float">
                          <Input type="text" pattern="[0-9]*" inputmode="numeric" size="large" value={this.state.protocolo} placeholder={getLabel('PROTOCOLO')} onChange={this.handleChangePro} />
                          <label>{getLabel('PROTOCOLO')}</label>
                        </Form.Item>
                         <Form.Item className="label-float">
                        <Input size="large" value={this.state.codigo} placeholder="Código Acesso" onChange={this.handleChangeCodigo} maxLength="4" />
                        <label>Código Acesso</label>
                        </Form.Item>
                        <Form.Item className="label-float">
                          <Input type="text" pattern="[0-9]*" inputmode="numeric" size="large" value={this.state.cpf} placeholder={getLabel('CPF_ENVOLVIDO')} onChange={this.handleChangeCpf} maxLength="14" />
                          <label>{getLabel('CPF_ENVOLVIDO')}</label>
                        </Form.Item>
                      <Form.Item>
                          <Button id="login" type="primary" size="large" block onClick={this.showModal}
                            disabled={!this.state.cpf || !this.state.protocolo || !this.state.codigo}
                          >
                            Pesquisar
                          </Button>
                      <Modal className="consulta-modal" id="resultado-consulta"
                        title= {getLabel('RESULTADO_CONSULTA')}
                        centered
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                      ><div className="dot"></div>
                        <div className="dot2"></div>
                        <div className="dot3"></div>
                        <div className="line"></div>
                        <div className="cross"></div>
                        <Title level={4}>{getLabel('NUMERO_REGISTRO')} {this.state.nuBoletim} /&nbsp;</Title>
                        <Title level={4}>{getLabel('POSTO')} {this.state.cdPostorodov} /&nbsp;</Title>
                        <Title level={4}>{getLabel('ANO')} {this.state.nuAno}</Title>
                        <div>
                          {this.state.alertaImp && this.state.botaoImprimir ?
                            <Alert
                              message={getLabel('IMPRESSAO_DEFINITIVA')}
                              type="success"
                              showIcon
                            />
                            :
                            null
                          }
                        </div>
                        <div>
                          {this.state.alertaCad && this.state.botaoEditar ?
                            <Alert
                              message={getLabel('CADASTRO_E_CORRECAO')}
                              type="success"
                              showIcon
                            />
                            :
                            null}
                        </div>
                        <div>
                          {this.state.alertaCad && this.state.botaoImprimir ?
                            <Alert
                              message={getLabel('IMPRESSAO_PARCIAL')}
                              type="success"
                              showIcon
                            />
                            :
                            null}
                        </div>
                        <div>
                          {!this.state.botaoImprimir && !this.state.botaoEditar ?
                           <Alert
                           message= {getLabel('AGUARDE_HOMOLOGACAO')}
                           type="warning"
                           showIcon
                         />
                         :
                         null
                        }
                        </div>
                        <a href={`${baseUrlAct}gerarRoat.action?uuid=${this.state.uuid}`} target="_blank" rel="noopener noreferrer"><Button className="btnimprimir" icon={<PrinterTwoTone />} size="large" disabled={!this.state.botaoImprimir} block>Imprimir</Button></a>
                        <Link to={`/veiculo/${this.state.uuid}`} ><Button className="btneditar" icon={<EditTwoTone />} size="large" disabled={!this.state.botaoEditar} block>Editar</Button></Link>
                      </Modal>
                      <Modal
                        title="Aviso"
                        visible={this.state.visibleMensagem}
                        onOk={this.handleOkMensagem}
                        onCancel={this.handleCancelMensagem}>
                        <p>{this.state.mensagem}</p>
                        <div className="dot"></div>
                        <div className="dot2"></div>
                        <div className="dot3"></div>
                        <div className="line"></div>
                        <div className="cross"></div>
                      </Modal>
                      <Button size="large" onClick={this.limpar} block>Limpar</Button> <Tooltip placement="bottomRight" title={ getLabel('TOOLTIP_YUTUBE') }>
                      <div className="youtube-list"  hidden={this.state.hiddenDesck}>
                        <a href="https://www.youtube.com/watch?v=KMSUC-C3gmo&list=PLzW1zqkM2g_IOOaWcQ_r1sHm3znfEHz8Y" target="_blank" rel="noopener noreferrer"><img src={logoYoutube} alt={getLabel('ALT_YOUTUBE')}/></a>
                      </div>
                      </Tooltip>                      
                      <Tooltip placement="leftBottom" title={ getLabel('TOOLTIP_YUTUBE') } defaultVisible={this.state.defaultVisible} >
                      <div id="fim" className={"youtube-list-mobile"} hidden={this.state.hiddenMob} >
                        <a href="https://www.youtube.com/watch?v=KMSUC-C3gmo&list=PLzW1zqkM2g_IOOaWcQ_r1sHm3znfEHz8Y" target="_blank" rel="noopener noreferrer"><img src={logoYoutube} alt={getLabel('ALT_YOUTUBE')} /></a>
                      </div>
                      </Tooltip>                      
                    </Form.Item>
                  </Form>
                </form>
              </Col>
            </Row>
          </div>
        </main>
          </div>
    );
  }
}

export default Login;