import React from 'react';
import { Row, Col } from 'antd';
import { Modal, Button } from 'antd';
import { Redirect } from 'react-router'
import { finalizaVeiculo, finalizarPreenchimentoRoat} from "../service/api";
import { SaveOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { getLabel } from '../shared'
const text = <span>{getLabel('FINALIZAR')}</span>;

export default class BtnFinalizar extends React.Component {
     constructor(props){
         super(props);
         this.state = {
            TextModalFinalizar: getLabel('TEXTO_MODAL_FINALIZAR'),
            visible: false,
            redirect: false, 
          };

     }

      showModal = () => {     
        this.setState({
          visible: true,
        });
       
      };
     
      handleOk = () => { 
        finalizaVeiculo(this.props.uuid);
        finalizarPreenchimentoRoat(this.props.uuid);
        this.setState({
          redirect: true
        });   
        
      };
    
      handleCancel = () => {
        this.setState({
          visible: false,
        });
      };
     
    render() {
        if(this.state.redirect){return <Redirect to="/" />}
        return (
            <React.Fragment>
                <Row className="protocolo-nest">                    
                    <Col md={4} className="btn-finalizar">
                        <Tooltip placement="topLeft" title={text}>
                        <Button disabled={ !this.props.fileListDianteira.length ||
                          !this.props.fileListTraseira.length ||
                          !this.props.fileListLateralDir.length ||
                          !this.props.fileListLateralEsq.length } 
                          size="large" type="primary" 
                          icon={<SaveOutlined />}  
                          onClick={this.showModal}>Finalizar </Button>
                        </Tooltip>
                    </Col>
                </Row>                
                <Modal 
                     title="Aviso"
                     visible={this.state.visible}
                     onOk={this.handleOk}
                     onCancel={this.handleCancel}>
                       <div className="dot"></div>
                        <div className="dot2"></div>
                        <div className="dot3"></div>
                        <div className="line"></div>
                        <div className="cross"></div>
                    <p>{this.state.TextModalFinalizar}</p>
                </Modal>
            </React.Fragment>
        )
    }
}