import React from 'react';
import { Row, Col } from 'antd';
import { Typography, } from 'antd';
import { Modal, Button } from 'antd';
import { Redirect } from 'react-router'
import { LogoutOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import car from '../imagens/header/car.png';
import { getBaseDados } from "../service/baseDados"
import {getLabel} from '../shared';

const { Title, Text } = Typography;
const text = <span>Sair</span>;

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalText: getLabel('TEXTO_MODAL_HEADER') ,
      ModalText2: getLabel('TEXTO_DOIS_MODAL_HEADER'),
      visible: false,
      redirect: false,
      marcaBaseDados: '',
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    if (this.props.onExit) {
        this.props.onExit();
    }
      this.setState({redirect: true });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    var othis = this;
      getBaseDados().then(function(response){
        othis.setState({marcaBaseDados: response})
      })
  }

  render() {
    if (this.state.redirect) { return <Redirect to="/" /> }
    return (
      <React.Fragment>
        <Row className="protocolo-nest">
          <Col lg={17} className="box-protocolo">
            <Title level={4}>{getLabel('PROTOCOLO')}: <Text>{this.props.protocolo}</Text></Title>
            <Title level={4}>{getLabel('CPF_ENVOLVIDO')}: <Text>{this.props.cpf}</Text></Title>
            <Text>{getLabel('NUMERO_REGISTRO')} {this.props.boletim} / {getLabel('POSTO')} {this.props.posto} / {getLabel('ANO')} {this.props.ano}</Text>
            <img id="car-protocolo" src={car} alt="Icone de carro"/>
          </Col>
          <Col lg={7} className="btn-sair">
            <Title level={2} className="ant-typography marcadagua">{this.state.marcaBaseDados}</Title>
            <Tooltip placement="topLeft" title={text}>
              <Button type="primary" icon={<LogoutOutlined />} onClick={this.showModal} />
            </Tooltip>
          </Col>
        </Row>
        <Modal
          title="Aviso"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}>
          <p>{this.state.ModalText}</p>
          <p>{this.state.ModalText2}</p>
          <div className="dot"></div>
          <div className="dot2"></div>
          <div className="dot3"></div>
          <div className="line"></div>
          <div className="cross"></div>
        </Modal>
      </React.Fragment>
    )
  }
}